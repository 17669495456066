/* eslint-disable react-hooks/exhaustive-deps */

import { Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import AddNodeMenu from "../../components/ChildNodes/AddNodeMenu";
import ChildNodesList from "../../components/ChildNodes/ChildNodesList";
import MUIBreadcrumb from "../../components/Breadcrumb";
import { OrgStructureNode } from "../../interfaces/OrgStructure";
import PagesHeader from "../../components/PagesHeader";
import { getCompanyRootNodes } from "../../apis/v1/OrgStructure";
import useBreadcrumbData from "../../hooks/useBreadcrumbData";
import { useParams } from "react-router-dom";

export default function RootNode() {
  const params = useParams();
  const [parentNode, setParentNode] = useState<OrgStructureNode>();
  const { companyName, rootNodeUuid } = useBreadcrumbData({
    company_uuid: params.company_uuid,
  });

  const requestNodes = () => {
    getCompanyRootNodes(params.company_uuid, params.root_node_uuid).then(
      (res) => {
        setParentNode(res.data);
      },
    );
  };

  useEffect(() => {
    requestNodes();
  }, [params.root_node_uuid]);

  return (
    <>
      <MUIBreadcrumb
        data={{
          stackList: [
            {
              name: "Companies",
              path: "/companies",
            },
            {
              name: companyName,
              path: `/company/${params.company_uuid}/root-node/${params.node_uuid}`,
            },
          ],
          current: parentNode?.name,
        }}
      />
      <PagesHeader title="Node Management" />
      <Card variant="outlined">
        <CardHeader
          title={"Root Node"}
          subheader="This is the root node details"
        />
        <CardContent sx={{ mb: 2 }}>
          <Typography> {parentNode?.name}</Typography>
        </CardContent>
      </Card>
      <Card variant="outlined">
        <CardHeader
          title={"Child Nodes"}
          subheader="This is the root node child nodes list"
        />
        <CardContent>
          <AddNodeMenu parentNode={parentNode} rootNodeUuid={rootNodeUuid} />
        </CardContent>
        <CardContent>
          <Grid container>
            <Grid item xs={12} md={6}>
              <ChildNodesList
                childNodes={parentNode?.children}
                updateNodeList={requestNodes}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
