/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, CardContent, CardHeader } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Customer } from "../../interfaces/User";
import UsersListTable from "../Users/v1/UsersListTable";
import { getCustomers } from "../../apis/v1/UserManagement";

export default function UsersList() {
  const [ customers, setCustomers ] = useState<Customer[]>([]);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    getCustomers({company_uuid: params.company_uuid}).then((res) => {
      if (res) {
        setCustomers(res.data);
      }
    });
  }, [])

  return (
    <Card variant="outlined">
      <CardHeader title="Users" subheader="Manage company users" />
      <CardContent>
        <Button
          variant={"outlined"}
          onClick={() =>
            navigate(
              `/company/${params.company_uuid}/node/${params.root_node_uuid}/add-user`,
            )
          }
        >
          Add
        </Button>
      </CardContent>
      <CardContent>
        {customers && (
          <UsersListTable
            root_node_uuid={params.root_node_uuid}
            customers={customers}
            setCustomers={setCustomers}
          />
        )}
      </CardContent>
    </Card>
  );
}
