import Detections from "../../components/DetectionsArea/Detections";
/* eslint-disable react-hooks/exhaustive-deps */
import MUIBreadcrumb from "../../components/Breadcrumb";
import useBreadcrumbData from "../../hooks/useBreadcrumbData";
import { useParams } from "react-router-dom";

export default function CameraDetectionsArea() {
  const params = useParams();
  const { companyName, siteName, cameraName, rootNodeUuid } = useBreadcrumbData(
    {
      company_uuid: params.company_uuid,
      site_uuid: params.site_uuid,
      camera_uuid: params.camera_uuid,
    },
  );
  return (
    <>
      <MUIBreadcrumb
        data={{
          stackList: [
            {
              name: "Companies",
              path: "/companies",
            },
            {
              name: companyName,
              path: `/company/${params.company_uuid}/root-node/${rootNodeUuid}`,
            },
            {
              name: siteName,
              path: `/company/${params.company_uuid}/node/${rootNodeUuid}/site/${params.site_uuid}`,
            },
            {
              name: cameraName,
              path: `/company/${params.company_uuid}/site/${params.site_uuid}/camera/${params.camera_uuid}`,
            },
          ],
          current: "image",
        }}
      />
      <Detections isEditable={true} />
    </>
  );
}
