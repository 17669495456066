import { Card, CardContent, CardHeader } from "@mui/material";
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { Company } from "../../interfaces/Company";
import { Customer } from "../../interfaces/User";
import MUIBreadcrumb from "../../components/Breadcrumb";
import UserDetailsCard from "../../components/Users/v1/UserDetails/UserDetailsCard";
import { getCompaniesDetails } from "../../apis/v1/Companies";
import { getCompanyNodes } from "../../apis/v1/OrgStructure";
import {
  getUserDetailsRequest,
} from "../../apis/v1/UserManagement";

export default function UserDetails() {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const [customer, setCustomer] = useState<Customer | any>();
  const [company, setCompany] = useState<Company>();
  const [userOrgAssignment, setUserOrgAssignment] = useState([]);
  const company_uuid = params?.company_uuid || searchParams.get("company_uuid");

  const getUserDetails = () => {
    getUserDetailsRequest(params.user_id)
      .then((res) => {
        setCustomer(res.data);
      })
  };

  const fetchCompany = async () => {
    try {
      const response = await getCompaniesDetails(company_uuid);
      setCompany(response.data);
    } catch (err) {
      console.log("Error while fetching company in user details", err);
    }
  };

  const fetchOrgAssignmentNodes = async () => {
    if (customer?.attributes?.org_structure_assignments?.length > 0) {
      const org_structure_assignments = await Promise.all(
        customer.attributes.org_structure_assignments.map(async (org_uuid) => {
          const res = await getCompanyNodes(company_uuid, org_uuid);
          return {
            uuid: res.data.uuid,
            name: res.data.name,
          };
        })
      );
      setUserOrgAssignment(org_structure_assignments);
    } else {
      setUserOrgAssignment([]);
    }
  };

  useEffect(() => {
    getUserDetails();
    fetchCompany();
  }, []);

  useEffect(() => {
    if (customer) {
      fetchOrgAssignmentNodes();
    }
  }, [customer]);

  const renderBreadcrumbData = () => {
    const stackList = params?.company_uuid
      ? [
          {
            name: "Companies",
            path: "/companies",
          },

          {
            name: company?.name,
            path: `/company/${params.company_uuid}/root-node/${params.node_uuid}`,
          },
        ]
      : [
          {
            name: "Users",
            path: "/users",
          },
        ];
    return stackList;
  };

  return (
    <>
      <MUIBreadcrumb
        data={{
          stackList: renderBreadcrumbData(),
          current: customer && customer.username,
        }}
      />
      {customer && (
        <Card variant="outlined">
          <CardHeader
            title="User Details"
            subheader="Details about this user and assigned organizations"
          />
          <CardContent>
            <UserDetailsCard
              customer={customer}
              company={company}
              userOrgAssignment={userOrgAssignment}
            />
          </CardContent>
        </Card>
      )}
    </>
  );
}