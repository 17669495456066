/* eslint-disable react-hooks/exhaustive-deps */
import { Card, CardContent, CardHeader } from "@mui/material";
import { activateCameraConnection, deactivateCameraConnection } from "../../../../../apis/v1/Camera/CameraConnection";
import { useEffect, useState } from "react";

import AllCamerasFilters from "./AllCamerasFilters";
import AllCamerasListTable from "./AllCamerasListTable";
import { getCompanyCameras } from "../../../../../apis/v1/Camera/Camera";
import { getDetectionZones } from "../../../../../apis/v1/DetectionZone/DetectionZones";
import { useParams } from "react-router-dom";

export default function AllCamerasCard({ sites }) {
  const params = useParams();
  const [filters, setFilters] = useState();
  const [cameras, setCameras] = useState([]);

  const getCamerasList = async () => {
    try {
      const response = await getCompanyCameras(params.company_uuid, filters);
      const enrichedCameras = await Promise.all(response.data.map(async (camera) => {
        const site_name = getSiteName(camera.site_uuid);
        const polygons_count = await getDetectionZones(params.company_uuid, camera.uuid).then(res => res.data.length);
        return { ...camera, site_name, polygons_count };
      }));
      setCameras(enrichedCameras);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getCamerasList();
  }, [params.company_uuid, filters]);

  const getSiteName = (site_uuid) => {
    return sites?.find((site) => site.uuid === site_uuid)?.name;
  };

  const deactivateConnectionRequest = (camera) => {
      deactivateCameraConnection(
        params.company_uuid,
        camera.site_uuid,
        camera.uuid,
        camera.camera_connection.uuid,
      ).then((res) => {
        if (res) {
          getCamerasList();
        }
      });
    };

    const activateConnectionRequest = (camera) => {
      activateCameraConnection(
        params.company_uuid,
        camera.site_uuid,
        camera.uuid,
        camera.camera_connection.uuid,
      ).then((res) => {
        if (res) {
          getCamerasList();
        }
      });
    };


  return (
    <Card variant="outlined">
      <CardHeader title="Cameras" subheader="A list of all cameras per company" />
      <CardContent>
        <AllCamerasFilters sites={sites} setFilters={setFilters}/>
        <AllCamerasListTable
          cameras={cameras}
          deactivateConnectionRequest={deactivateConnectionRequest}
          activateConnectionRequest={activateConnectionRequest}
          onCameraDeleted={getCamerasList}
        />
      </CardContent>
    </Card>
  );
}
