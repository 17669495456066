import { IconButton, TableCell, TableRow } from "@mui/material";

import DeleteTemplate from "./DeleteTemplate";
import EditIcon from "@mui/icons-material/Edit";
import { IS_ACTIVATED_CONNECTION_TOOLTIP_TITLE } from "../../../../utilis/shared";
import MUITable from "../../../Table";
import Switch from "@mui/material/Switch";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";

export default function CompanyCameraConnectionTemplateList({
  templates,
  setTemplates,
  company_uuid,
  handleChangeStatus,
}) {
  const navigate = useNavigate();

  return (
    <MUITable
      tableHeaderCells={[
        {
          id: "name",
          name: "Name",
        },
        {
          id: "is_http",
          name: "Is Http",
        },
        {
          id: "status",
          name: "status",
          tooltip_title: IS_ACTIVATED_CONNECTION_TOOLTIP_TITLE,
        },
        {
          id: "actions",
          name: "",
        },
      ]}
      hasPagination={true}
      data={templates}
      tableBodyRow={(row: any) => (
        <TableRow key={row.uuid} data-testid="company-template-row">
          <TableCell>{row.name}</TableCell>
          <TableCell>{row.is_http ? "Yes" : "No"}</TableCell>
          <TableCell>
            {row.is_active ? "Active" : "Deactive"}
            <Switch
              checked={row.is_active}
              onChange={() =>
                handleChangeStatus(company_uuid, row.uuid, row.is_active)
              }
              aria-label="switch"
              size="small"
            />
          </TableCell>
          <TableCell align="right">
            <IconButton
              size="small"
              onClick={() =>
                navigate(
                  `/connection/company-template/${company_uuid}/${row.uuid}`,

                )
              }
            >
              <VisibilityIcon fontSize="small" />
            </IconButton>
            <IconButton
              size="small"
              data-testid="editBtn"
              disabled={row.is_active}
              onClick={() =>
                navigate(
                  `/connection/company-template/${company_uuid}/edit/${row.uuid}`,
                )
              }
            >
              <EditIcon fontSize="small" />
            </IconButton>
            <DeleteTemplate
              is_active={row.is_active}
              template_uuid={row.uuid}
              company_uuid={company_uuid}
              templates={templates}
              setTemplates={setTemplates}
            />
          </TableCell>
        </TableRow>
      )}
    />
  );
}
