import { Box } from "@mui/material";

export default function UserOrgAssignments({ userOrgAssignment }) {
  return (
    <>
      <Box component={"h4"} mb={0} mt={1}>
        Organisation Assignments
      </Box>
      {userOrgAssignment?.length > 0 ? (
        <Box component={"ul"} pl={3} my={0}>
          {userOrgAssignment?.map(
            (org_structure_assignment) => (
              <Box
                component={"li"}
                mb={0}
                data-testid="org-assignment-item"
                key={org_structure_assignment.uuid}
              >
                {org_structure_assignment.name}
              </Box>
            ),
          )}
        </Box>
      ) : (
        <Box>-</Box>
      )}
    </>
  );
}
