import { Button, Card, CardContent, CardHeader } from "@mui/material";
import {
  activateOcuconTemplate,
  deactivateOcuconTemplate,
} from "../../apis/v1/Camera/CompanyCameraTemplates";
import { useNavigate, useParams } from "react-router-dom";

import { CameraConnectionResponse } from "../../interfaces/Camera";
import CompanyCameraConnectionTemplateList from "../Cameras/v1/CompanyTemplate/ListTable";

interface CameraConnectionTemplatesListProps {
  templates: CameraConnectionResponse[];
  setTemplates: (sites: CameraConnectionResponse[]) => void;
  getCompanyDetailsRequest: () => void;
}

export default function CameraConnectionTemplatesList({
  templates,
  setTemplates,
  getCompanyDetailsRequest,
}: CameraConnectionTemplatesListProps) {
  const navigate = useNavigate();
  const params = useParams();

  const handleChangeStatus = (company_uuid, template_uuid, is_active) => {
    if (is_active) {
      deactivateOcuconTemplate(company_uuid, template_uuid).then((res) => {
        if (res) {
          getCompanyDetailsRequest();
        }
      });
    } else {
      activateOcuconTemplate(company_uuid, template_uuid).then((res) => {
        if (res) {
          getCompanyDetailsRequest();
        }
      });
    }
  };

  return (
    <Card variant="outlined">
      <CardHeader
        title="Camera Connection Templates"
        subheader="A company camera connection template is a company wide template which you can use on a camera as a starting point when creating a connection"
      />
      <CardContent>
        <Button
          variant={"outlined"}
          onClick={() =>
            navigate(`/connection/company-template/add/${params.company_uuid}`)
          }
        >
          Add
        </Button>
      </CardContent>
      <CardContent>
        <CompanyCameraConnectionTemplateList
          templates={templates}
          company_uuid={params.company_uuid}
          setTemplates={setTemplates}
          handleChangeStatus={handleChangeStatus}
        />
      </CardContent>
    </Card>
  );
}
