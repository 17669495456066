import MUISnackbar from "./components/Snackbar";
import RoutesList from "./routes/RoutesList";

function App() {
  return (
    <>
      <RoutesList />
      <MUISnackbar />
    </>
  );
}

export default App;
