import { Button, Grid, TextField } from "@mui/material";

import MUISelect from "../../../../Controls/Select";
import { useState } from "react";

export default function AllCamerasFilters({
  sites,
  setFilters,
}) {
  const [showReset, setShowReset] = useState<boolean>(false);
  const [siteUUID, setSiteUUID] = useState<string>(null);
  const [cameraName, setCameraName] = useState<string>(null);

  const handleFilter = () => {
    setShowReset(true);
    setFilters({
      camera_name: cameraName,
      site_uuid: siteUUID,
    });
  };

  return (
    <Grid container columnSpacing={2} mb={2}>
      <Grid item xs={12} sm={3}>
        <TextField
          label="Camera name"
          id="camera_name"
          name="camera_name"
          onChange={(e) => setCameraName(e.target.value)}
          size="small"
          fullWidth
          margin="normal"
          value={cameraName || ""}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <MUISelect
          label="Site"
          labelId="label-site-uuid"
          id={"select-site-uuid"}
          name="site_uuid"
          handleChange={(e) => {
            setSiteUUID(e.target.value);
          }}
          list={sites}
          margin="normal"
          value={siteUUID || ""}
          keyItem="uuid"
          keyValue="name"
        />
      </Grid>

      <Grid
        display={"flex"}
        gap={2}
        alignItems={"center"}
        justifyContent={"end"}
        item
        xs={12}
        sm={6}
      >
        <Button
          variant="outlined"
          data-testid="filter-btn"
          onClick={handleFilter}
        >
          Filter
        </Button>
        {showReset && (
          <Button
            onClick={() => {
              setFilters({
                site_uuid: null,
                camera_name: null,
              });
              setShowReset(false);
              setSiteUUID(null);
              setCameraName('');
            }}
          >
            Reset
          </Button>
        )}
      </Grid>
    </Grid>
  );
}
