/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, CardContent, CardHeader } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Subscription } from "../../interfaces/Subscription";
import SubscriptionsListTable from "../Subscriptions/SubscriptionListTable";
import { getSubscriptions } from "../../apis/v1/Subscriptions/Subscriptions";

export default function SubscriptionsList() {
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    getSubscriptions({
      company_uuid: params.company_uuid,
    }).then((res) => {
      if (res) {
        setSubscriptions(res.data);
      }
    });
  }, []);

  return (
    <Card variant="outlined">
      <CardHeader title="Subscriptions" subheader="Manage subscriptions" />
      <CardContent>
        <Button
          variant={"outlined"}
          onClick={() =>
            navigate(`/company/${params.company_uuid}/add-subscription`)
          }
        >
          Add
        </Button>
      </CardContent>
      <CardContent>
        {subscriptions && (
          <SubscriptionsListTable
            subscriptions={subscriptions}
          />
        )}
      </CardContent>
    </Card>
  );
}
