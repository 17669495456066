import { ADD_USER_COMMENT, ADD_USER_TO_SITES_NOTE } from "../../utilis/shared";
import { Grid, Typography } from "@mui/material";
import {
  REDIRECTION_URLS_ENUM,
  redirectionURLs,
} from "../../utilis/redirectionURLs";
import {
  getFlattenChildrenNodesList,
  resetList,
} from "../../utilis/format-child-nodes";
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import AddUserForm from "../../components/Users/v1/AddUserForm";
import MUIBreadcrumb from "../../components/Breadcrumb";
import PagesHeader from "../../components/PagesHeader";
import { addCustomer } from "../../apis/v1/UserManagement";
import { getCompanyRootNodes } from "../../apis/v1/OrgStructure";
import { showSnackbar } from "../../utilis/snackbar";
import useBreadcrumbData from "../../hooks/useBreadcrumbData";

export default function AddUser() {
  const navigate = useNavigate();
  const params = useParams();
  const [parentNodes, setParentNodes] = useState([]);
  const { companyName } = useBreadcrumbData({
    company_uuid: params.company_uuid
  });

  const handleSubmit = (values) => {
    const client_id = redirectionURLs.find(
      (url) => url.id === values.redirect_uri,
    )?.client_id;
    const selected_redirect_uri = redirectionURLs.find(
      (url) => url.id === values.redirect_uri,
    );
    addCustomer({
      ...values,
      client_id,
      username: values.email,
      company_uuid: params.company_uuid,
      org_structure_assignments: values.org_structure_assignments
        ? [values.org_structure_assignments]
        : [],
      redirect_uri:
        selected_redirect_uri.id !== REDIRECTION_URLS_ENUM.SPILL_MOBILE_APP
          ? selected_redirect_uri.redirect_uri
          : null,
    })
      .then((res) => {
        if (res) {
          showSnackbar("User added successfully", "success");
          navigate(
            `/company/${params.company_uuid}/node/${params.node_uuid}/user/${res.data?.id}`
          );
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    resetList();
    getCompanyRootNodes(params.company_uuid, params.node_uuid).then((res) => {
      const nodes = getFlattenChildrenNodesList(res.data);
      setParentNodes(nodes);
    });

    return () => {
      resetList();
    };
  }, []);

  return (
    <>
      <MUIBreadcrumb
        data={{
          stackList: [
            {
              name: "Companies",
              path: "/companies",
            },
            {
              name: companyName,
              path: `/company/${params.company_uuid}/root-node/${params.node_uuid}`,
            },
          ],
          current: "Add User",
        }}
      />
      <PagesHeader title="Add Company User" />
      <Typography color="error" className="text-danger">
        {ADD_USER_TO_SITES_NOTE}
      </Typography>
      <p>{ADD_USER_COMMENT}</p>
      <Grid container>
        <Grid item xs={12} sm={6} md={4}>
          <AddUserForm parentNodes={parentNodes} handleSubmit={handleSubmit} />
        </Grid>
      </Grid>
    </>
  );
}
