import { createCompanyChildNode, getCompanyChildNode } from "../../apis/v1/OrgStructure";
import { useEffect, useState } from "react";
/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate, useParams } from "react-router-dom";

import AddChildNodeForm from "../../components/ChildNodes/AddChildNodeForm";
import { Grid } from "@mui/material";
import MUIBreadcrumb from "../../components/Breadcrumb";
import { OrgStructureNodePayload } from "../../interfaces/OrgStructure";
import PagesHeader from "../../components/PagesHeader";
import { showSnackbar } from "../../utilis/snackbar";
import useBreadcrumbData from "../../hooks/useBreadcrumbData";

export default function AddChildNode() {
  const navigate = useNavigate();
  const params = useParams();
  const { companyName , rootNodeUuid } = useBreadcrumbData({
    company_uuid:params.company_uuid , 
    node_uuid: params.node_uuid
  });
  const [breadcrumbNodes, setBreadcrumbNodes] = useState<
    { name: string; path: string }[]
  >([]);

  const fetchParentNodes = async (
    nodeUuid: string,
  ) => {
    const response = await getCompanyChildNode(params.company_uuid, nodeUuid);
    const currentNode = response.data;
      setBreadcrumbNodes((prev) => [
        {
          name: currentNode.name,
          path: `/company/${params.company_uuid}/root-node/${rootNodeUuid}/node/${currentNode.uuid}/children`,
        },
        ...prev,
      ]);

    if (currentNode.parent_node_uuid) {
      await fetchParentNodes(currentNode.parent_node_uuid);
    }
  };


  const handleSubmit = (values: Omit<OrgStructureNodePayload, 'parent_node_uuid'>) => {
    createCompanyChildNode(params.company_uuid, {
      ...values,
      parent_node_uuid: params.node_uuid,
    }).then((res) => {
      if (res) {
        showSnackbar("Done successfully", "success");
        navigate(-1);
      }
    });
  };

  useEffect(() => {
    fetchParentNodes(params.node_uuid);
  }, [params.node_uuid]);

  return (
    <>
      <MUIBreadcrumb
        data={{
          stackList: [
            {
              name: "Companies",
              path: "/companies",
            },
            {
              name: companyName,
              path: `/company/${params.company_uuid}/root-node/${params.node_uuid}`,
            },
            ...breadcrumbNodes,
          ],
          current: "Add child node",
        }}
      />
      <PagesHeader title="Add Child Node" />
      <Grid container>
        <Grid item xs={12} md={6} lg={4}>
          <AddChildNodeForm handleSubmit={handleSubmit} />
        </Grid>
      </Grid>
    </>
  );
}
