/* eslint-disable react-hooks/exhaustive-deps */

import { Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import AddNodeMenu from "../../components/ChildNodes/AddNodeMenu";
import ChildNodesList from "../../components/ChildNodes/ChildNodesList";
import MUIBreadcrumb from "../../components/Breadcrumb";
import { OrgStructureNode } from "../../interfaces/OrgStructure";
import PagesHeader from "../../components/PagesHeader";
import { getCompanyChildNode } from "../../apis/v1/OrgStructure";
import { node_types } from "../../utilis/enum";
import useBreadcrumbData from "../../hooks/useBreadcrumbData";
import { useParams } from "react-router-dom";

export default function ChildNodes() {
  const params = useParams();
  const [parentNode, setParentNode] = useState<OrgStructureNode>();
  const [breadcrumbNodes, setBreadcrumbNodes] = useState<
    { name: string; path: string }[]
  >([]);
  const { companyName, rootNodeUuid } = useBreadcrumbData({
    company_uuid: params.company_uuid,
  });
  const requestNodes = () => {
    getCompanyChildNode(params.company_uuid, params.node_uuid).then((res) => {
      setParentNode(res.data);
      fetchParentNodes(params.node_uuid, res.data.uuid);
    });
  };

  const fetchParentNodes = async (
    nodeUuid: string,
    currentNodeUuid?: string,
  ) => {
    const response = await getCompanyChildNode(params.company_uuid, nodeUuid);
    const currentNode = response.data;
    if (currentNodeUuid !== currentNode.uuid) {
      setBreadcrumbNodes((prev) => [
        {
          name: currentNode.name,
          path: `/company/${params.company_uuid}/root-node/${rootNodeUuid}/node/${currentNode.uuid}/children`,
        },
        ...prev,
      ]);
    }

    if (currentNode.parent_node_uuid) {
      await fetchParentNodes(currentNode.parent_node_uuid);
    }
  };

  console.log(breadcrumbNodes);

  useEffect(() => {
    requestNodes();
    setBreadcrumbNodes([]);
  }, [params.node_uuid]);

  return (
    <>
      <MUIBreadcrumb
        data={{
          stackList: [
            {
              name: "Companies",
              path: "/companies",
            },
            {
              name: companyName,
              path: `/company/${params.company_uuid}/root-node/${rootNodeUuid}`,
            },
            ...breadcrumbNodes,
          ],
          current: parentNode?.name,
        }}
      />
      <PagesHeader title="Node Management" />
      <Card variant="outlined">
        <CardHeader
          title={"Parent Node"}
          subheader="This is the parent node details"
        />
        <CardContent sx={{ mb: 2 }}>
          <Typography> {parentNode?.name}</Typography>
        </CardContent>
      </Card>
      {parentNode?.type !== node_types.SITE_NODE && (
        <Card variant="outlined">
          <CardHeader
            title={"Child Nodes"}
            subheader="This is the child node children list"
          />
          <CardContent>
            <AddNodeMenu parentNode={parentNode} rootNodeUuid={rootNodeUuid} />
          </CardContent>

          <CardContent>
            <Grid container>
              <Grid item xs={12} md={6}>
                <ChildNodesList
                  childNodes={parentNode?.children}
                  updateNodeList={requestNodes}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
    </>
  );
}
