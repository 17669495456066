import * as Pages from "./index";

import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";

import PrivateRoute from "./PrivateRoute";

const routes = [
  { path: "/", element: <Pages.Auth />, isPrivate: false },
  { path: "/callback", element: <Pages.TokenExchange />, isPrivate: false },
  { path: "/companies", element: <Pages.Companies />, isPrivate: true },
  { path: "/company/add", element: <Pages.AddCompany />, isPrivate: true },
  {
    path: "/company/edit/:company_uuid",
    element: <Pages.EditCompany />,
    isPrivate: true,
  },
  {
    path: "/company/:company_uuid/root-node/:root_node_uuid",
    element: <Pages.CompanyDetails />,
    isPrivate: true,
  },
  {
    path: "/company/:company_uuid/node/:node_uuid/site/add",
    element: <Pages.AddSite />,
    isPrivate: true,
  },
  {
    path: "/company/:company_uuid/node/:node_uuid/site/:site_uuid/edit",
    element: <Pages.EditSite />,
    isPrivate: true,
  },
  {
    path: "/company/:company_uuid/node/:node_uuid/site/:site_uuid",
    element: <Pages.SiteDetails />,
    isPrivate: true,
  },
  { path: "/admins", element: <Pages.AdminsList />, isPrivate: true },
  { path: "/admin/add", element: <Pages.AddAdmin />, isPrivate: true },
  {
    path: "/connection/ocucon-camera-connection-templates",
    element: <Pages.OcuconTemplateList />,
    isPrivate: true,
  },
  {
    path: "/connection/ocucon-camera-connection-template/add",
    element: <Pages.AddOcuconTemplate />,
    isPrivate: true,
  },
  {
    path: "/connection/ocucon-camera-connection-template/edit/:uuid",
    element: <Pages.EditOcuconTemplate />,
    isPrivate: true,
  },
  {
    path: "/connection/ocucon-camera-connection-template/edit/attributes/:uuid",
    element: <Pages.EditOcuconTemplateAttributes />,
    isPrivate: true,
  },
  {
    path: "/connection/ocucon-camera-connection-template/:uuid",
    element: <Pages.OcuconTemplateDetails />,
    isPrivate: true,
  },
  {
    path: "/connection/company-template/add/:company_uuid",
    element: <Pages.AddCompanyCameraTemplate />,
    isPrivate: true,
  },
  {
    path: "/connection/company-template/:company_uuid/edit/:uuid",
    element: <Pages.EditCompanyCameraTemplate />,
    isPrivate: true,
  },
  {
    path: "/connection/company-template/:company_uuid/edit/attributes/:uuid",
    element: <Pages.EditCompanyCameraTemplateAttributes />,
    isPrivate: true,
  },
  {
    path: "/connection/company-template/:company_uuid/:template_uuid",
    element: <Pages.CompanyCameraTemplateDetails />,
    isPrivate: true,
  },
  {
    path: "/company/:company_uuid/site/:site_uuid/camera/add",
    element: <Pages.AddCamera />,
    isPrivate: true,
  },
  {
    path: "/company/:company_uuid/site/:site_uuid/camera/:camera_uuid/edit",
    element: <Pages.EditCamera />,
    isPrivate: true,
  },
  {
    path: "/company/:company_uuid/site/:site_uuid/camera/:camera_uuid",
    element: <Pages.CameraDetails />,
    isPrivate: true,
  },
  {
    path: "/company/:company_uuid/site/:site_uuid/camera/:camera_uuid/connection/add",
    element: <Pages.AddCameraConnection />,
    isPrivate: true,
  },
  {
    path: "/company/:company_uuid/site/:site_uuid/camera/:camera_uuid/connection/:connection_uuid",
    element: <Pages.CameraConnectionDetails />,
    isPrivate: true,
  },
  {
    path: "/company/:company_uuid/site/:site_uuid/camera/:camera_uuid/connection/:connection_uuid/edit",
    element: <Pages.EditCameraConnectionTemplate />,
    isPrivate: true,
  },
  {
    path: "/company/:company_uuid/site/:site_uuid/camera/:camera_uuid/connection/:connection_uuid/attributes/edit",
    element: <Pages.EditCameraConnectionAttributes />,
    isPrivate: true,
  },
  {
    path: "/settings/camera/default-settings",
    element: <Pages.DefaultCameraSettingsList />,
    isPrivate: true,
  },
  {
    path: "/settings/camera/default-settings/add",
    element: <Pages.AddCameraDefaultSettings />,
    isPrivate: true,
  },
  {
    path: "/settings/camera/default-settings/:uuid",
    element: <Pages.CameraDefaultSettingsDetails />,
    isPrivate: true,
  },
  {
    path: "/settings/camera/default-settings/edit/:uuid",
    element: <Pages.EditCameraDefaultSettings />,
    isPrivate: true,
  },
  {
    path: "/company/:company_uuid/add-company-overrides",
    element: <Pages.AddCompanyCameraSettingsOverrides />,
    isPrivate: true,
  },
  {
    path: "/company/:company_uuid/site/:site_uuid/add-site-overrides",
    element: <Pages.AddSiteCameraSettingsOverrides />,
    isPrivate: true,
  },
  {
    path: "/company/:company_uuid/site/:site_uuid/camera/:camera_uuid/add-camera-overrides",
    element: <Pages.AddCameraSettingsOverrides />,
    isPrivate: true,
  },
  {
    path: "/company/:company_uuid/node/:node_uuid/add-user",
    element: <Pages.AddUser />,
    isPrivate: true,
  },
  {
    path: "/company/:company_uuid/node/:node_uuid/edit-user/:user_id",
    element: <Pages.EditUser />,
    isPrivate: true,
  },
  {
    path: "/company/:company_uuid/node/:node_uuid/user/:user_id",
    element: <Pages.User />,
    isPrivate: true,
  },
  {
    path: "/users/:user_id",
    element: <Pages.User />,
    isPrivate: true,
  },
  {
    path: "/company/:company_uuid/node/:node_uuid/user/:user_id/update-permissions",
    element: <Pages.UpdatePermissions />,
    isPrivate: true,
  },
  {
    path: "/company/:company_uuid/node/:node_uuid/user/:user_id/update-licenses",
    element: <Pages.UpdateLicenses />,
    isPrivate: true,
  },
  {
    path: "/company/:company_uuid/root-node/:root_node_uuid/node",
    element: <Pages.RootNode />,
    isPrivate: true,
  },
  {
    path: "/company/:company_uuid/root-node/:root_node_uuid/node/:node_uuid/children",
    element: <Pages.ChildNodes />,
    isPrivate: true,
  },
  {
    path: "/company/:company_uuid/parent-node/:node_uuid/add-child-node",
    element: <Pages.AddChildNode />,
    isPrivate: true,
  },
  {
    path: "/company/:company_uuid/site/:site_uuid/camera/:camera_uuid/image/:image_uuid",
    element: <Pages.CameraDetectionsArea />,
    isPrivate: true,
  },
  {
    path: "/company/:company_uuid/add-company-incident-class",
    element: <Pages.AddCompanyIncidentClass />,
    isPrivate: true,
  },
  {
    path: "/global-incident-classes/add",
    element: <Pages.AddGlobalIncidentClass />,
    isPrivate: true,
  },
  {
    path: "/global-incident-classes",
    element: <Pages.GlobalIncidentClassesList />,
    isPrivate: true,
  },
  {
    path: "/global-incident-classes/:incident_class_uuid/edit",
    element: <Pages.EditGlobalIncidentClass />,
    isPrivate: true,
  },
  {
    path: "/company/:company_uuid/root-node/:root_node_uuid/notifications/add-rule",
    element: <Pages.AddRule />,
    isPrivate: true,
  },
  {
    path: "/company/:company_uuid/root-node/:root_node_uuid/notifications/rule/:rule_uuid/edit",
    element: <Pages.EditRule />,
    isPrivate: true,
  },
  {
    path: "/company/:company_uuid/root-node/:root_node_uuid/notifications/rule/:rule_uuid",
    element: <Pages.RuleDetails />,
    isPrivate: true,
  },
  {
    path: "/company/:company_uuid/notifications/rule/:rule_uuid/type/:rule_type/integration/add",
    element: <Pages.AddRuleIntegration />,
    isPrivate: true,
  },
  {
    path: "/company/:company_uuid/notifications/rule/:rule_uuid/type/:rule_type/integration/:integration_uuid/edit",
    element: <Pages.EditRuleIntegration />,
    isPrivate: true,
  },
  {
    path: "/company/:company_uuid/add-incident-cause",
    element: <Pages.AddIncidentCause />,
    isPrivate: true,
  },
  {
    path: "/company/:company_uuid/incident-cause/edit/:incident_cause_uuid",
    element: <Pages.EditIncidentCause />,
    isPrivate: true,
  },
  { path: "/sync", element: <Pages.Sync />, isPrivate: true },
  { path: "/services", element: <Pages.ServicesList />, isPrivate: true },
  { path: "/service/add", element: <Pages.AddService />, isPrivate: true },
  {
    path: "/service/edit/:service_uuid",
    element: <Pages.EditService />,
    isPrivate: true,
  },
  {
    path: "/service/:service_uuid",
    element: <Pages.ServiceDetails />,
    isPrivate: true,
  },
  {
    path: "/service/:service_uuid/tier/add",
    element: <Pages.AddTier />,
    isPrivate: true,
  },
  {
    path: "/service/:service_uuid/tier/:tier_uuid/edit",
    element: <Pages.EditTier />,
    isPrivate: true,
  },
  {
    path: "/service/:service_uuid/tier/:tier_uuid",
    element: <Pages.TierDetails />,
    isPrivate: true,
  },
  {
    path: "/service/:service_uuid/feature/:feature_uuid/edit",
    element: <Pages.EditFeature />,
    isPrivate: true,
  },
  {
    path: "/service/:service_uuid/feature/add",
    element: <Pages.AddFeature />,
    isPrivate: true,
  },
  {
    path: "/service/:service_uuid/consumable/:consumable_uuid/edit",
    element: <Pages.EditConsumable />,
    isPrivate: true,
  },
  {
    path: "/service/:service_uuid/consumable/add",
    element: <Pages.AddConsumable />,
    isPrivate: true,
  },
  {
    path: "/service/:service_uuid/consumable/:consumable_uuid",
    element: <Pages.ConsumableDetails />,
    isPrivate: true,
  },
  {
    path: "/service/:service_uuid/consumable/:consumable_uuid/consumable_addon/add",
    element: <Pages.AddConsumableAddons />,
    isPrivate: true,
  },
  {
    path: "/service/:service_uuid/consumable/:consumable_uuid/consumable_addon/:consumable_addon_uuid/edit",
    element: <Pages.EditConsumableAddons />,
    isPrivate: true,
  },
  {
    path: "/service/:service_uuid/consumable/:consumable_uuid/consumable_addon/:consumable_addon_uuid",
    element: <Pages.ConsumableAddonsDetails />,
    isPrivate: true,
  },
  { path: "/add-policy", element: <Pages.AddPolicy />, isPrivate: true },
  {
    path: "/edit-policy/:policy_uuid",
    element: <Pages.EditPolicy />,
    isPrivate: true,
  },
  { path: "/users", element: <Pages.AllUsersList />, isPrivate: true },
  { path: "/reasons/add", element: <Pages.AddReason />, isPrivate: true },
  {
    path: "/subscriptions",
    element: <Pages.SubscriptionsList />,
    isPrivate: true,
  },
  {
    path: "/reasons/:reason_uuid/edit",
    element: <Pages.EditReason />,
    isPrivate: true,
  },
  {
    path: "/company/:company_uuid/add-subscription",
    element: <Pages.AddSubscription />,
    isPrivate: true,
  },
  {
    path: "/company/:company_uuid/service/:service_uuid/add-credit",
    element: <Pages.AddCredit />,
    isPrivate: true,
  },
  {
    path: "/company/:company_uuid/service/:service_uuid/subscription/:subscription_uuid",
    element: <Pages.SubscriptionDetails />,
    isPrivate: true,
  },
  {
    path: "*",
    element: <Navigate to="/companies" replace />,
  },
];

export const router = createBrowserRouter(
  routes.map(({ isPrivate, ...route }: any) => ({
    ...route,
    element: isPrivate ? (
      <PrivateRoute>{route.element}</PrivateRoute>
    ) : (
      route.element
    ),
  })),
);

export default function RoutesList() {
  return <RouterProvider router={router} />;
}
