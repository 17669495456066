import { Button, Card, CardContent, CardHeader } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

export default function OrgStructure() {
  const params = useParams();
  const navigate = useNavigate();
  return (
    <Card variant="outlined">
      <CardHeader
        title="Root Node"
        subheader="This is the company root node and child nodes"
      />
      <CardContent sx={{ mb: 2 }}>
        <Button
          variant={"outlined"}
          onClick={() =>
            navigate(
              `/company/${params.company_uuid}/root-node/${params.root_node_uuid}/node`,
            )
          }
        >
          View
        </Button>
      </CardContent>
    </Card>
  );
}
