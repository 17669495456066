import {
  editGlobalIncidentClass,
  getGlobalIncidentClass,
} from "../../apis/v1/DetectionZone/GlobalIncidentClasses";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import EditGlobalIncidentClassForm from "../../components/Labels/EditGlobalIncidentClassForm";
import { GIncidentClass } from "../../interfaces/IncidentClass";
import { Grid } from "@mui/material";
import PagesHeader from "../../components/PagesHeader";
import { showSnackbar } from "../../utilis/snackbar";

export default function EditGlobalIncidentClass() {
  const navigate = useNavigate();
  const params = useParams();
  const [incidentClass, setIncidentClass] = useState<GIncidentClass>();

  useEffect(() => {
    getGlobalIncidentClass(params.incident_class_uuid).then((res ) => {
      if (res) {
        setIncidentClass(res.data);
      }
    });
  }, [params.company_uuid, params.incident_class_uuid]);

  const handleSubmitForm = (values: Omit<GIncidentClass, "uuid">) => {
    editGlobalIncidentClass(params.incident_class_uuid, values).then((res) => {
      if (res) {
        showSnackbar("Done successfully", "success");
        navigate(`/global-incident-classes`);
      }
    });
  };

  return (
    <>
      <PagesHeader title="Edit Global Incident Class" />
      <Grid container>
        <Grid item xs={12} sm={6} md={4}>
          {incidentClass && (
            <EditGlobalIncidentClassForm
              incidentClass={incidentClass}
              handleSubmitForm={handleSubmitForm}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}
