import { Button, Card, CardContent, CardHeader } from "@mui/material";
import {
  activateCameraConnection,
  deactivateCameraConnection,
} from "../../../../apis/v1/Camera/CameraConnection";
import { useNavigate, useParams } from "react-router-dom";

import CameraListTable from "./ListTable";

export default function CamerasListCard({
  getSiteDetailsRequest,
  cameras,
  site,
}) {
  const params = useParams();
  const navigate = useNavigate();

  const deactivateConnectionRequest = (camera) => {
    deactivateCameraConnection(
      params.company_uuid,
      params.site_uuid,
      camera.uuid,
      camera.camera_connection.uuid,
    ).then((res) => {
      if (res) {
        getSiteDetailsRequest();
      }
    });
  };

  const activateConnectionRequest = (camera) => {
    activateCameraConnection(
      params.company_uuid,
      params.site_uuid,
      camera.uuid,
      camera.camera_connection.uuid,
    ).then((res) => {
      if (res) {
        getSiteDetailsRequest();
      }
    });
  };

  return (
    <Card variant="outlined">
      <CardHeader title="Camera List" subheader="A list of cameras" />
      <CardContent>
        <Button
          variant={"outlined"}
          onClick={() =>
            navigate(
              `/company/${params?.company_uuid}/site/${params?.site_uuid}/camera/add`,
            )
          }
        >
          Add
        </Button>
      </CardContent>
      <CardContent>
        <CameraListTable
          cameras={cameras}
          onCameraDeleted={() => getSiteDetailsRequest()}
          deactivateConnectionRequest={deactivateConnectionRequest}
          activateConnectionRequest={activateConnectionRequest}
        />
      </CardContent>
    </Card>
  );
}
