import { useEffect, useState } from "react";

import { getCameraDetails } from "../apis/v1/Camera/Camera";
import { getCompaniesDetails } from "../apis/v1/Companies"; // Adjust the import path as necessary
import { getCompanyChildNode } from "../apis/v1/OrgStructure";
import { getSiteDetails } from "../apis/v1/Sites"; // Adjust the import path as necessary

const useBreadcrumbData = ({
  company_uuid,
  site_uuid,
  camera_uuid,
  node_uuid,
}: {
  company_uuid?: string | undefined;
  site_uuid?: string | undefined;
  camera_uuid?: string | undefined;
  node_uuid?: string | undefined;
}) => {
  const [companyName, setCompanyName] = useState<string | null>(null);
  const [siteName, setSiteName] = useState<string | null>(null);
  const [cameraName, setCameraName] = useState<string | null>(null);
  const [nodeName, setNodeName] = useState<string | null>(null);
  const [rootNodeUuid, setRootNodeUuid] = useState<string | null>(null)

  useEffect(() => {
    if (company_uuid) {
      getCompaniesDetails(company_uuid).then((res) => {
        if (res) {
          setCompanyName(res.data.name);
          setRootNodeUuid(res.data.root_node_uuid)
        }
      });
    }
  }, [company_uuid]);

  useEffect(() => {
    if (site_uuid) {
      getSiteDetails(site_uuid, company_uuid).then((res) => {
        if (res) {
          console.log(res.data);
          setSiteName(res.data.name);
        }
      });
    }
  }, [site_uuid, company_uuid]);

  useEffect(() => {
    if (camera_uuid) {
      getCameraDetails(company_uuid, site_uuid, camera_uuid).then((res) => {
        if (res) {
          setCameraName(res.data.name);
        }
      });
    }
  }, [site_uuid, company_uuid, camera_uuid]);

  useEffect(() => {
    if (node_uuid) {
      getCompanyChildNode(company_uuid, node_uuid).then((res) => {
        if (res) {
          setNodeName(res.data.name);
        }
      });
    }
  }, [company_uuid, node_uuid]);

  return { companyName, siteName, cameraName, nodeName , rootNodeUuid };
};

export default useBreadcrumbData;
