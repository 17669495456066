import { Attribute, CameraConnectionResponse } from "../../interfaces/Camera";
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, CardContent, CardHeader } from "@mui/material";
import {
  getCameraConnectionAttributes,
  getCameraConnectionDetails,
} from "../../apis/v1/Camera/CameraConnection";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import DetailsCard from "../../components/Cameras/v1/CameraConnection/DetailsCard";
import MUIBreadcrumb from "../../components/Breadcrumb";
import useBreadcrumbData from "../../hooks/useBreadcrumbData";

export default function CameraConnectionDetails() {
  const [cameraConnection, setCameraConnection] =
    useState<CameraConnectionResponse>();
  const [attributes, setAttributes] = useState<Array<Attribute>>();
  const params = useParams();
  const navigate = useNavigate();
  const { companyName, siteName, cameraName } = useBreadcrumbData({
    company_uuid: params.company_uuid,
    site_uuid: params.site_uuid,
    camera_uuid: params.camera_uuid,
  });

  useEffect(() => {
    getCameraConnectionDetails(
      params.company_uuid,
      params.site_uuid,
      params.camera_uuid,
      params.connection_uuid,
    ).then((res) => {
      if (res) {
        setCameraConnection(res.data);
      }
    });
    getCameraConnectionAttributes(
      params.company_uuid,
      params.site_uuid,
      params.camera_uuid,
      params.connection_uuid,
    ).then((res) => {
      if (res) {
        setAttributes(res.data.attributes);
      }
    });
  }, []);

  return (
    <>
      <MUIBreadcrumb
        data={{
          stackList: [
            {
              name: "Companies",
              path: "/companies",
            },
            {
              name: companyName,
              path: `/company/${params.company_uuid}/root-node/${params.node_uuid}`,
            },
            {
              name: siteName,
              path: `/company/${params.company_uuid}/node/${params.node_uuid}/site/${params.site_uuid}`,
            },
            {
              name: cameraName,
              path: `/company/${params.company_uuid}/site/${params.site_uuid}/camera/${params.camera_uuid}`,
            },
          ],
          current: cameraConnection && cameraConnection?.name,
        }}
      />
      <Card variant="outlined">
        <CardHeader
          title="Camera Connections Details"
          subheader="This is a camera connection details page"
        />
        <CardContent>
          {cameraConnection && !cameraConnection.is_active && (
            <Button
              variant={"outlined"}
              onClick={() =>
                navigate(
                  `/company/${params.company_uuid}/site/${params.site_uuid}/camera/${params.camera_uuid}/connection/${params.connection_uuid}/edit`,
                )
              }
            >
              Edit
            </Button>
          )}
        </CardContent>
        <CardContent>
          {cameraConnection && attributes && (
            <DetailsCard
              cameraConnection={cameraConnection}
              attributes={attributes}
            />
          )}
        </CardContent>
      </Card>
    </>
  );
}
