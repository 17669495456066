import { IconButton, Link, TableCell, TableRow, Tooltip } from "@mui/material";

import { Company } from "../../interfaces/Company";
import MUITable from "../Table";
import { OrgStructureNode } from "../../interfaces/OrgStructure";
import { Subscription } from "../../interfaces/Subscription";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { formatDate } from "../../utilis/formatDate";
import { orderType } from "../../enums/Order";
import { useNavigate } from "react-router-dom";

interface AllSubscriptionsListTableProps {
  subscriptions: Subscription[];
  rootNodes: Array<OrgStructureNode>;
  companies: Array<Company>;
}

export default function AllSubscriptionsListTable({
  subscriptions,
  rootNodes,
  companies,
}: Readonly<AllSubscriptionsListTableProps>) {
  const navigate = useNavigate();
  const getRootNodePerCompany = (company_uuid) => {
    const rootNode = rootNodes?.find(
      (node) => node?.company_uuid === company_uuid,
    );
    return rootNode;
  };
  const getUserCompany = (company_uuid: string) => {
    const company = companies?.find((company) => company.uuid === company_uuid);
    return company;
  };

  return (
    <MUITable
      tableHeaderCells={[
        {
          id: "company_uuid",
          name: "Company",
        },
        {
          id: "service.name",
          name: "Service",
        },

        {
          id: "tier.name",
          name: "Tier",
        },
        {
          id: "expires_at_utc",
          name: "Expiry date",
        },
        {
          id: "activated_at_utc",
          name: "Activated on",
        },
        {
          id: "actions",
          name: "",
        },
      ]}
      hasPagination={true}
      orderByOption="activated_at_utc"
      orderOption={orderType.DESC}
      data={subscriptions}
      tableBodyRow={(row: Subscription) => (
        <TableRow key={row.uuid}>
          <TableCell>
            {
              <Tooltip title={"View company"} placement="top">
                <Link
                  onClick={() =>
                    navigate(
                      `/company/${row?.company_uuid}/root-node/${getRootNodePerCompany(row?.company_uuid)?.uuid}`,
                    )
                  }
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  {getUserCompany(row.company_uuid)?.name || "-"}
                </Link>
              </Tooltip>
            }
          </TableCell>
          <TableCell>{row.service.name}</TableCell>
          <TableCell>{row.tier.name}</TableCell>

          <TableCell>
            {row.expires_at_utc
              ? formatDate(row.expires_at_utc)
              : "No expiration date"}
          </TableCell>
          <TableCell>{formatDate(row.activated_at_utc)}</TableCell>

          <TableCell align="right">
            <IconButton
              size="small"
              data-testid="view-subscription-btn"
              onClick={() =>
                navigate(
                  `/company/${row.company_uuid}/service/${row.service.uuid}/subscription/${row.uuid}?fromAllSubscriptions=true`,
                )
              }
            >
              <VisibilityIcon fontSize="small" />
            </IconButton>
          </TableCell>
        </TableRow>
      )}
    />
  );
}
