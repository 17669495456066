import {
  AdminPayload,
  Customer,
  CustomerPayload,
  User,
} from "../../interfaces/User";

import { AxiosPromise } from "axios";
import { axiosInstance } from "../config/axiosInstance";
import { redirectionURLs } from "../../utilis/redirectionURLs";

const USER_MANAGEMENT_BASE_URL = "/user-management/v1";
const ADMIN_MANAGMENT_BASE = `${USER_MANAGEMENT_BASE_URL}/admins`;
const CUSTOMER_MANAGMENT_BASE = `${USER_MANAGEMENT_BASE_URL}/customers`;

// ADMIN USERS MANAGEMENT
export const addAdmin = (data: AdminPayload): AxiosPromise<null> => {
  return axiosInstance.post(ADMIN_MANAGMENT_BASE, data);
};

export const getAdmins = (): AxiosPromise<User[]> => {
  return axiosInstance.get(ADMIN_MANAGMENT_BASE);
};

export const deleteAdmin = (admin_id: string): AxiosPromise<void> => {
  return axiosInstance.delete(`${ADMIN_MANAGMENT_BASE}/${admin_id}`);
};

export const sendAdminInviteEmail = (admin_id: string): AxiosPromise<void> => {
  return axiosInstance.post(
    `${ADMIN_MANAGMENT_BASE}/${admin_id}/send-invite-email`,
    {},
    {
      params: {
        redirect_uri: process.env.REACT_APP_REDIRECT_URL_ADMIN_DASHBOARD,
      },
    },
  );
};

// CUSTOMER USERS MANAGEMENT
export const addCustomer = (data: CustomerPayload): AxiosPromise<User> => {
  return axiosInstance.post(`${CUSTOMER_MANAGMENT_BASE}`, data);
};

export const editCustomer = (
  customer_uuid: string,
  data: Omit<CustomerPayload, "username" | "company_uuid">,
): AxiosPromise<null> => {
  return axiosInstance.patch(
    `${CUSTOMER_MANAGMENT_BASE}/${customer_uuid}`,
    data,
  );
};

export const getCustomers = ({
  company_uuid,
  email,
  first_name,
  last_name,
}: {
  company_uuid?: string;
  email?: string;
  first_name?: string;
  last_name?: string;
}): AxiosPromise<Customer[]> => {
  return axiosInstance.get(`${CUSTOMER_MANAGMENT_BASE}`, {
    params: {
      ...(email && { email }),
      ...(first_name && { first_name }),
      ...(last_name && { last_name }),
      ...(company_uuid && { company_uuid }),
    },
  });
};

export const deleteCustomer = (customer_uuid: string): AxiosPromise<void> => {
  return axiosInstance.delete(`${CUSTOMER_MANAGMENT_BASE}/${customer_uuid}`);
};

export const sendCustomerInviteEmail = (
  customer_uuid: string,
  redirect_uri?: string,
): AxiosPromise<void> => {
  const client_id = redirectionURLs.find(url => url.redirect_uri === redirect_uri)?.client_id;
  return axiosInstance.post(
    `${CUSTOMER_MANAGMENT_BASE}/${customer_uuid}/send-invite-email`,
    {},
    {
      params: {
        ...(redirect_uri && { redirect_uri }),
        ...(redirect_uri && { client_id })
      },
    },
  );
};

export const getAllUsers = ({
  email,
  first_name,
  last_name,
}: {
  email?: string,
  first_name?: string,
  last_name?: string,
}): AxiosPromise<Customer[]> => {
  return axiosInstance.get(`${USER_MANAGEMENT_BASE_URL}/users/all`, {
    params: {
      ...(email && { email }),
      ...(first_name && { first_name }),
      ...(last_name && { last_name }),
    },
  });
};

export const getUserDetailsRequest = (customer_uuid: string) => {
  return axiosInstance.get(`${CUSTOMER_MANAGMENT_BASE}/${customer_uuid}`);
};