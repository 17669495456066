/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, CardContent, CardHeader } from "@mui/material";
import {
  getCompanyRules,
  getRuleTypes,
} from "../../apis/v1/Notifications/Rules";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import NotificationsRulesTable from "../NotificationsRules";
import RulesFilters from "../NotificationsRules/RulesFilters";
import { getCompanyRootNodes } from "../../apis/v1/OrgStructure";
import { getFlattenChildrenNodesList } from "../../utilis/format-child-nodes";
import { getRuleIntegrations } from "../../apis/v1/Notifications/Integrations";

export default function CompanyNotificationRulesList() {
  const [renderedRules, setRenderedRules] = useState(null);
  const [originalRules, setOriginalRules] = useState(null);
  const [rules, setRules] = useState(null);
  const [ruleIntegration, setRuleIntegration] = useState(null);
  const [nodes, setNodes] = useState(null);
  const [ruleTypes, setRuleTypes] = useState([]);

  const [filters, setFilters] = useState({
    node_name: "",
    node_type: "",
    integration_type: "",
    rule_type: "",
  });

  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const [rulesResponse, ruleTypesResponse] = await Promise.all([
          getCompanyRules(params.company_uuid),
          getRuleTypes(),
        ]);

        setRules(rulesResponse.data);
        setRuleTypes(ruleTypesResponse.data);
      } catch (error) {
        console.error("Error fetching company data:", error);
      }
    };

    fetchCompanyData();
  }, [params.company_uuid]);

  useEffect(() => {
    const fetchIntegrationsAndNodes = async () => {
      if (rules?.length > 0) {
        try {
          const integrationsPromises = rules.map((rule) =>
            getRuleIntegrations(params.company_uuid, rule.uuid).then((res) => ({
              rule_uuid: rule.uuid,
              types: res.data.map((integration) => integration.type),
            })),
          );

          const integrations = await Promise.all(integrationsPromises);

          setRuleIntegration(integrations);

          const res = await getCompanyRootNodes(
            params.company_uuid,
            params.root_node_uuid,
          );
          const nodesList = getFlattenChildrenNodesList(res.data);
          setNodes(nodesList);

          setRenderedRules(rules);
          setOriginalRules(rules);
        } catch (error) {
          console.error("Error fetching integrations or nodes:", error);
        }
      }
    };

    fetchIntegrationsAndNodes();
  }, [rules]);

  const getRuleIntegration = (rule_uuid : string) => {  
    return ruleIntegration?.find(integration => integration?.rule_uuid === rule_uuid);
  }

  const getNode = (node_uuid: string) => {
    return nodes?.find(node => node?.uuid === node_uuid);
  }


  return (
    <Card variant="outlined">
      <CardHeader
        title="Company Notification Rules"
        subheader="A list of notification rules assigned to this company and its nodes"
      />
      <CardContent>
        <Button
          variant={"outlined"}
          onClick={() =>
            navigate(
              `/company/${params.company_uuid}/root-node/${params?.root_node_uuid}/notifications/add-rule`,
            )
          }
        >
          Add
        </Button>
      </CardContent>
      <CardContent>
        <RulesFilters
          filters={filters}
          setFilters={setFilters}
          setRenderedRules={setRenderedRules}
          originalRules={originalRules}
          ruleTypes={ruleTypes}
          ruleIntegration={ruleIntegration}
          nodes={nodes}
        />
      </CardContent>
      <CardContent>
        <NotificationsRulesTable
          renderedRules={renderedRules}
          setRenderedRules={setRenderedRules}
          company_uuid={params.company_uuid}
          root_node_uuid={params.root_node_uuid}
          setOriginalRules={setOriginalRules}
          originalRules={originalRules}
          getNode={getNode}
          getRuleIntegration={getRuleIntegration}
        />
      </CardContent>
    </Card>
  );
}
