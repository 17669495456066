import { Button, Card, CardContent, CardHeader } from "@mui/material";
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import CameraConnectionTemplatesList from "../../components/CompanyDetails/CameraConnectionTemplatesList";
import CameraSettingsOverridesList from "../../components/CompanyDetails/CameraSettingsOverridesList";
import { Company } from "../../interfaces/Company";
import CompanyIncidentClassList from "../../components/CompanyDetails/CompanyIncidentClassList";
import CompanyNotificationRulesList from "../../components/CompanyDetails/CompanyNotificationRulesList";
import DetailsCard from "../../components/Companies/v1/DetailsCard";
import IncidentCausesList from "../../components/CompanyDetails/IncidentCausesList";
import MUIBreadcrumb from "../../components/Breadcrumb";
import OrgStructure from "../../components/CompanyDetails/OrgStructure";
import { SiteResponse } from "../../interfaces/Site";
import SitesList from "../../components/CompanyDetails/SitesList";
import SubscriptionsList from "../../components/CompanyDetails/SubscriptionsList";
import UsersList from "../../components/CompanyDetails/UsersList";
import { getCompaniesDetails } from "../../apis/v1/Companies";
import AllCamerasCard from "../../components/Cameras/v1/Camera/AllCameras/AllCamerasCard";

export default function CompanyDetails() {
  const navigate = useNavigate();
  const params = useParams();
  const [company, setCompany] = useState<Company>();
  const [sites, setSites] = useState<SiteResponse[]>();
  const [templates, setTemplates] = useState(null);

  const getCompanyDetailsRequest = () => {
    getCompaniesDetails(params.company_uuid).then((res) => {
      if (res) {
        setCompany(res.data);
        setSites(res.data.sites);
        setTemplates(res.data.company_camera_templates);
      }
    });
  };

  useEffect(() => {
    getCompanyDetailsRequest();
  }, [params.company_uuid]);

  return (
    <>
      <MUIBreadcrumb
        data={{
          stackList: [
            {
              name: "Companies",
              path: "/companies",
            },
          ],
          current: company?.name,
        }}
      />
      {company && (
        <>
          <Card variant="outlined">
            <CardHeader
              title="Company Details"
              subheader="Details about this company"
            />
            <CardContent>
              <Button
                variant={"outlined"}
                onClick={() => navigate(`/company/edit/${params.company_uuid}?fromDetails=true`)}
              >
                Edit
              </Button>
            </CardContent>
            <CardContent>
              <DetailsCard company={company} />
            </CardContent>
          </Card>
          <SubscriptionsList  />
          <SitesList sites={sites} setSites={setSites} />
          <AllCamerasCard sites={sites} />
          <UsersList />
          <CameraConnectionTemplatesList
            templates={templates}
            setTemplates={setTemplates}
            getCompanyDetailsRequest={getCompanyDetailsRequest}
          />
          <CameraSettingsOverridesList />
          <OrgStructure  />
          <CompanyIncidentClassList />
          <CompanyNotificationRulesList  />
          <IncidentCausesList />
        </>
      )}
    </>
  );
}
