import { Company, CompanyFormValues } from "../../interfaces/Company";
import {
  editCompany,
  getCompaniesDetails,
  getServices,
} from "../../apis/v1/Companies";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { Country } from "../../interfaces/Address";
import EditCompanyForm from "../../components/Companies/v1/EditCompanyForm";
import { Grid } from "@mui/material";
import PagesHeader from "../../components/PagesHeader";
import { Service } from "../../interfaces/Service";
import { getCountries } from "../../apis/v1/Countries";
import { showSnackbar } from "../../utilis/snackbar";

export default function EditCompany() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [services, setServices] = useState<Array<Service>>([]);
  const [company, setCompany] = useState<Company>();
  const [countries, setCountries] = useState<Array<Country>>([]);
  const params = useParams();

  useEffect(() => {
    getCompaniesDetails(params.company_uuid).then((res) => {
      res && setCompany(res.data);
    });

    getServices().then((res) => {
      res && setServices(res.data);
    });
    getCountries().then((res) => {
      setCountries(res.data);
    });
  }, [params.company_uuid]);

  const handleSubmitCompany = (values: CompanyFormValues) => {
    editCompany({
      data: {
        ...values,
        phones:
          values.phone && values.country_code
            ? [
                {
                  number: values.phone,
                  country_code: values.country_code,
                },
              ]
            : [],
        addresses: [
          {
            address_1: values.address_1,
            address_2: values.address_2,
            city: values.city,
            postcode: values.postcode,
            county: values.county,
            country_uuid: values.country,
          },
        ],
      },
      company_uuid: params.company_uuid,
    }).then((res) => {
      if (res) {
        showSnackbar("Done successfully", "success");
        searchParams.get("fromDetails")
          ? navigate(
              `/company/${params.company_uuid}/root-node/${company.root_node_uuid}`,
            )
          : navigate("/companies");
      }
    });
  };

  return (
    <>
      <PagesHeader title="Edit Company Details" />
      <Grid container>
        <Grid item xs={12} sm={6} md={4}>
          {company && (
            <EditCompanyForm
              countries={countries}
              company={company}
              services={services}
              handleSubmitCompany={handleSubmitCompany}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}
