import "react-edit-text/dist/index.css";

import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import DeleteChildNode from "./DeleteChildNode";
import { EditText } from "react-edit-text";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { editCompanyChildNode } from "../../apis/v1/OrgStructure";
import { node_types } from "../../utilis/enum";
import { showSnackbar } from "../../utilis/snackbar";

export default function ChildNodesList({ childNodes, updateNodeList }) {
  const navigate = useNavigate();
  const params = useParams();
  const handleEditNode = (node_uuid: string, name: string) => {
    const data = {
      name,
      parent_node_uuid: params.node_uuid
        ? params.node_uuid
        : params.root_node_uuid,
    };
    editCompanyChildNode(params.company_uuid, node_uuid, data).then((res) => {
      if (res) {
        showSnackbar("Done Successfully", "success");
      }
    });
  };

  return (
    <TableContainer sx={{ borderTop: "1px solid #e7e7e7", mb: 2 }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>Name</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Type</TableCell>
            <TableCell sx={{ width: "30%" }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {childNodes?.length > 0 ? (
            childNodes.map((node) => {
              return (
                <TableRow key={node.uuid} data-testid="node-row">
                  <TableCell>
                    <EditText
                      data-testid={"override_value"}
                      name="override_value"
                      defaultValue={node.name}
                      onSave={({ value }) => handleEditNode(node.uuid, value)}
                      style={{ margin: 0 }}
                    />
                  </TableCell>
                  <TableCell>{node.type}</TableCell>
                  <TableCell>
                    <IconButton
                      size="small"
                      onClick={() =>
                        navigate(
                          `/company/${params.company_uuid}/root-node/${params.root_node_uuid}/node/${node.uuid}/children`,
                        )
                      }
                    >
                      <VisibilityIcon fontSize="small" />
                    </IconButton>
                    {node.type !== node_types.SITE_NODE && (
                      <DeleteChildNode
                        node_uuid={node.uuid}
                        updateNodeList={updateNodeList}
                      />
                    )}
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell sx={{ textAlign: "center" }} colSpan={3}>
                No Data
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
