import { AxiosError } from "axios";
import { isRouteNotValid } from "../../utilis/isRouteNotValid";
import { refreshTokenHandler } from "./refreshTokenHandler";
import { router } from "../../routes/RoutesList";
import { showSnackbar } from "../../utilis/snackbar";

export const isError = (error: AxiosError) => {
  if (!error.response) {
    return true;
  }

  return error.response.status !== 401;
};

export const isErrorResponseHasDetails = (error: AxiosError) => {
  return !(
    !error ||
    !error.response ||
    !error.response.data ||
    !error.response.data["errors"]
  );
};

export const getDefaultErrorMsg = () => {
  return "Something went wrong";
};

export const getErrorLocation = (error) => {
  let errorLocation = "";
  if (error.response.data.errors[0].location.length > 0) {
    errorLocation = `at [ ${error.response.data.errors[0].location.join(" , ")} ]`;
    return errorLocation;
  }
  return errorLocation;
};

export const getErrorMessageText = (error) => {
  if (!error || !error.response || !isErrorResponseHasDetails(error)) {
    return getDefaultErrorMsg();
  }
  return `${JSON.stringify(error.response.data.errors[0].message)} ${getErrorLocation(error)}`;
};

export const shouldRefreshToken = (error: any) => {
  if (!error.response) {
    return false;
  }
  if (error.response.status !== 401) {
    return false;
  }
  if (error.config._retry) {
    return false;
  }
  return !error.config.url.includes("token");
};

export const errorHandler = (error: AxiosError) => {
  if (shouldRefreshToken(error)) {
    return refreshTokenHandler(error);
  }

  if (isError(error)) {
    showSnackbar(getErrorMessageText(error));
  }

  if(isRouteNotValid(error)){
    showSnackbar('Not found or invalid URL');
    router.navigate(`/companies`, { replace: true }) 
  }

  return Promise.reject(error);
};
