import { Card, CardContent, CardHeader } from "@mui/material";
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import CancelSubscription from "../../components/Subscriptions/CancelSubscription";
import CreditsListCard from "../../components/Credits/CreditsListCard";
import DetailsCard from "../../components/Subscriptions/DetailsCard";
import MUIBreadcrumb from "../../components/Breadcrumb";
import SubscriptionHistoriesList from "../../components/Subscriptions/SubscriptionHistoryList";
import { getLicenses } from "../../apis/v1/Licenses";
import { getSubscriptionDetails } from "../../apis/v1/Subscriptions/Subscriptions";
import useBreadcrumbData from "../../hooks/useBreadcrumbData";

export default function SubscriptionDetails() {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const [subscription, setSubscription] = useState(null);
  const [licenses, setLicenses] = useState([]);
  const { companyName, rootNodeUuid } = useBreadcrumbData({
    company_uuid: params.company_uuid,
  });

  const getSubscriptionDetailsRequest = () => {
    getSubscriptionDetails(
      params.service_uuid,
      params.subscription_uuid,
      params.company_uuid,
    ).then((res) => {
      if (res) {
        setSubscription(res.data);
      }
    });
  };

  const getLicensesRequest = () => {
    getLicenses(params.company_uuid, params.service_uuid).then((res) => {
      setLicenses(res.data);
    });
  };

  useEffect(() => {
    getSubscriptionDetailsRequest();
    getLicensesRequest();
  }, [params.subscription_uuid]);

  const renderBreadcrumbData = () => {
    const fromAllSubscriptions = searchParams.get("fromAllSubscriptions");
    const stackList = fromAllSubscriptions
      ? [
          {
            name: "Subscriptions",
            path: "/subscriptions",
          },
        ]
      : [
          {
            name: "Companies",
            path: "/companies",
          },

          {
            name: companyName,
            path: `/company/${params.company_uuid}/root-node/${rootNodeUuid}`,
          },
        ];
    return stackList;
  };

  return (
    <>
      <MUIBreadcrumb
        data={{
          stackList: renderBreadcrumbData(),
          current: subscription?.service?.name,
        }}
      />
      <Card variant="outlined">
        <CardHeader
          title="Subscription Details"
          subheader="Details about this subscription"
        />

        <CardContent>
          {subscription && (
            <DetailsCard subscription={subscription} licenses={licenses} />
          )}
        </CardContent>
        <CancelSubscription
          subscription={subscription}
          setSubscription={setSubscription}
        />
      </Card>
      <SubscriptionHistoriesList />
      <CreditsListCard is_subscription_active={subscription?.active} />
    </>
  );
}
