import axios, { AxiosError } from "axios";

import { errorHandler } from "./errorHandler";
import { setLoader } from "../../store/slices/loaderSlice";
import store from "../../store";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_OCUCON_GATEWAY_BASE_URL,
});

// Interceptor to do something before request is sent
axiosInstance.interceptors.request.use((config: any) => {
    const access_token = localStorage.getItem("access_token");
    store.dispatch(setLoader(true));
    if (access_token) {
      config.headers["Authorization"] = `Bearer ${access_token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => {
    store.dispatch(setLoader(false));
    return response;
  },
  async function (error: AxiosError) {
    store.dispatch(setLoader(false));
    return errorHandler(error);
  },
);
