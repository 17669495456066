import React from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import MUISelect from "../../../Controls/Select";
import { FieldArray, Formik, FormikErrors, getIn } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { SchemaOf } from "yup";
import DeleteIcon from "@mui/icons-material/Delete";
import GroupedTemplatesSelect from "../../../Controls/GroupedTemplatesSelect";
import { template_attributes_types } from "../../../../utilis/templateAttributesFilter";
import { setFieldValueWithoutSpaces } from "../../../../utilis/shared";
import {
  Attribute,
  AttributeTypes,
  AuthType,
  BaseTemplates,
  CameraConnection,
  FormValues,
  Replace,
  SetFieldError,
  SetFieldValue,
} from "../../../../interfaces/Camera";
import { getCompanyTemplateAttributesByUUID } from "../../../../apis/v1/Camera/CompanyCameraTemplates";
import { getOcuconTemplateAttributesByUUID } from "../../../../apis/v1/Camera/OcuconCameraTemplates";

const schema: SchemaOf<FormValues> = yup.object().shape({
  base_template: yup.string(),
  name: yup.string().required(),
  is_http: yup.boolean(),
  url_template: yup
    .string()
    .nullable()
    .when("is_http", (is_http: boolean) => {
      if (is_http) {
        return yup.string().required();
      }
    }),
  attributes_fields: yup.array(),
  attributes: yup.array(),
  auth_type: yup.mixed<AuthType>().oneOf(Object.values(AuthType)),
  username: yup.string().nullable(),
  password: yup.string().nullable(),
});

const initialValues: FormValues = {
  base_template: "",
  name: "",
  is_http: true,
  url_template: "",
  auth_type: AuthType.BASIC,
  username: null,
  password: null,
  attributes: [],
  attributes_fields: [
    { key: "", value: "", type: AttributeTypes.URL_REPLACEMENT_VALUE },
    { key: "", value: "", type: AttributeTypes.QUERY_PARAMS },
    { key: "", value: "", type: AttributeTypes.SETTINGS },
  ],
};

const handleBaseTemplateChange = async (
  value: React.ChangeEvent<HTMLSelectElement>,
  setFieldValue: SetFieldValue,
  handleChange: (event: React.ChangeEvent<HTMLSelectElement>) => void,
  baseTemplates: BaseTemplates,
  params: Record<string, string>,
) => {
  handleChange(value);
  const selectedTemplate = baseTemplates.ocucon_templates
    .concat(baseTemplates.company_templates)
    .find((template: CameraConnection) => template.uuid === value.target.value);

  if (selectedTemplate) {
    setFieldValue("base_template", value.target.value);
    setFieldValue("is_http", selectedTemplate.is_http);
    setFieldValue("url_template", selectedTemplate.url_template);
    setFieldValue("auth_type", selectedTemplate.auth_type);
    setFieldValue("username", selectedTemplate.username);
    setFieldValue("password", selectedTemplate.password);

    const attributes =
      selectedTemplate.type === "ocucon_template"
        ? await getOcuconTemplateAttributesByUUID(value.target.value)
        : await getCompanyTemplateAttributesByUUID(
            params.company_uuid,
            value.target.value,
          );

    if (attributes) {
      setFieldValue("attributes", attributes.data.attributes);
    }
  }
};

const renderAttributeFields = (
  attributes: Attribute[],
  values: FormValues,
  setFieldValue: SetFieldValue,
  handleBlur: React.FocusEventHandler<HTMLInputElement>,
  attributeType: AttributeTypes,
) => {
  return attributes.map((attr_val, attrValIndex) => {
    const attrVal = getIn(values, `attributes[${attrValIndex}].value`);
    if (attr_val.type !== attributeType) {
      return null;
    }
    return (
      <Grid
        container
        key={attrValIndex}
        spacing={2}
        justifyContent="space-between"
      >
        <Grid item xs={4}>
          <Box component="h6" m={0}>
            Key
          </Box>
          <Box component="p" mt={0} sx={{ overflowWrap: "break-word" }}>
            {attr_val.key}
          </Box>
        </Grid>
        <Grid item xs={4}>
          <TextField
            variant="outlined"
            data-testid="value"
            label="value"
            name={`attributes[${attrValIndex}].value`}
            value={attrVal || ""}
            onChange={(e) =>
              setFieldValueWithoutSpaces(
                e,
                `attributes[${attrValIndex}].value`,
                setFieldValue,
              )
            }
            onBlur={handleBlur}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <IconButton
            size="small"
            data-testid="deleteSettings"
            aria-label="delete"
            sx={{ mt: "0.3em" }}
            onClick={() =>
              setFieldValue(
                "attributes",
                values.attributes.filter((_, i) => attrValIndex !== i),
              )
            }
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
    );
  });
};

const renderSettingsFields = (
  attributes_fields: Attribute[],
  item: string,
  values: FormValues,
  setFieldValue: SetFieldValue,
  handleBlur: React.FocusEventHandler<HTMLInputElement>,
  setFieldError: SetFieldError,
  replace: Replace,
  errors: FormikErrors<FormValues>,
) => {
  return attributes_fields.map((attr, index) => {
    const key = `attributes_fields[${index}].key`;
    const value = `attributes_fields[${index}].value`;
    const keyErr = getIn(errors, key);
    const valueErr = getIn(errors, value);
    if (attr.type === item) {
      return (
        <React.Fragment key={index}>
          <Grid container alignItems="start" mb={2} spacing={2}>
            <Grid item xs={4}>
              <TextField
                variant="outlined"
                data-testid="settingsField"
                label="key"
                name={key}
                value={attr.key}
                onChange={(e) =>
                  setFieldValueWithoutSpaces(e, key, setFieldValue)
                }
                onBlur={handleBlur}
                size="small"
                error={Boolean(keyErr)}
                helperText={keyErr}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                variant="outlined"
                data-testid="value"
                label="value"
                name={value}
                value={attr.value}
                onChange={(e) =>
                  setFieldValueWithoutSpaces(e, value, setFieldValue)
                }
                onBlur={handleBlur}
                size="small"
                error={Boolean(valueErr)}
                helperText={valueErr}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <Button
                type="button"
                variant="outlined"
                color="primary"
                name={"add attribute"}
                onClick={() =>
                  addAttributeField(
                    attr,
                    values,
                    setFieldValue,
                    replace,
                    index,
                    setFieldError,
                  )
                }
              >
                Add
              </Button>
            </Grid>
          </Grid>
        </React.Fragment>
      );
    }
    return null;
  });
};

const addAttributeField = (
  attr: Attribute,
  values: FormValues,
  setFieldValue: SetFieldValue,
  replace: Replace,
  index: number,
  setFieldError: SetFieldError,
) => {
  if (!attr.key || !attr.value) {
    setFieldError(`attributes_fields[${index}].key`, "Required");
    setFieldError(`attributes_fields[${index}].value`, "Required");
  } else {
    setFieldValue("attributes", [
      ...values.attributes,
      { key: attr.key, value: attr.value, type: attr.type },
    ]);
    replace(index, { key: "", value: "", type: attr.type });
  }
};

const AddConnectionForm: React.FC<{
  baseTemplates: BaseTemplates;
  handleSubmitForm: (values: FormValues) => void;
  authTypes: AuthType[];
}> = ({ baseTemplates, handleSubmitForm, authTypes }) => {
  const navigate = useNavigate();
  const params = useParams();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={handleSubmitForm}
    >
      {({
        errors,
        values,
        touched,
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
        setFieldError,
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid container mt={3}>
            <Grid item xs={12} sm={6} md={4}>
              <GroupedTemplatesSelect
                dataTestid="base_template"
                label="Base Template"
                labelId="base_template"
                id="base_template"
                name="base_template"
                handleChange={(e) =>
                  handleBaseTemplateChange(
                    e,
                    setFieldValue,
                    handleChange,
                    baseTemplates,
                    params,
                  )
                }
                keyItem="uuid"
                keyValue="name"
                list={baseTemplates}
                value={values.base_template}
              />
              <small>
                {" "}
                Note: Select a predefined template. The template list is a
                mixture of ocucon and company templates.
              </small>
              <TextField
                type="text"
                id="name"
                data-testid="name"
                label="Name*"
                fullWidth
                margin="normal"
                name="name"
                error={Boolean(errors.name && touched.name)}
                helperText={errors.name && touched.name ? errors.name : ""}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                size="small"
              />
              <Box display="flex" justifyContent="end">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="is_http"
                      data-testid="is_http"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      checked={values.is_http}
                    />
                  }
                  label="Is http connection?"
                />
              </Box>
              {values.is_http && (
                <>
                  <TextField
                    type="text"
                    id="url_template"
                    data-testid="url_template"
                    label="url template*"
                    fullWidth
                    margin="normal"
                    name="url_template"
                    onChange={(e) =>
                      setFieldValueWithoutSpaces(
                        e,
                        "url_template",
                        setFieldValue,
                      )
                    }
                    onBlur={handleBlur}
                    value={values.url_template}
                    size="small"
                    error={Boolean(errors.url_template && touched.url_template)}
                    helperText={
                      errors.url_template && touched.url_template
                        ? errors.url_template
                        : ""
                    }
                  />
                  <MUISelect
                    error={
                      errors.auth_type && touched.auth_type
                        ? errors.auth_type
                        : ""
                    }
                    dataTestid="auth_type"
                    label="Auth Type"
                    labelId="auth_type"
                    id="auth_type"
                    name="auth_type"
                    handleChange={handleChange}
                    list={authTypes}
                    margin="normal"
                    value={values.auth_type}
                    setFieldValue={setFieldValue}
                  />
                  <Grid container spacing={2}>
                    <Grid item sm={6}>
                      <TextField
                        type="text"
                        id="username"
                        data-testid="username"
                        label="Username"
                        fullWidth
                        margin="normal"
                        name="username"
                        onChange={(e) =>
                          setFieldValueWithoutSpaces(
                            e,
                            "username",
                            setFieldValue,
                          )
                        }
                        onBlur={handleBlur}
                        value={values.username || ""}
                        size="small"
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        type="text"
                        id="password"
                        data-testid="password"
                        label="Password"
                        fullWidth
                        margin="normal"
                        name="password"
                        onChange={(e) =>
                          setFieldValueWithoutSpaces(
                            e,
                            "password",
                            setFieldValue,
                          )
                        }
                        onBlur={handleBlur}
                        value={values.password || ""}
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </>
              )}
              {template_attributes_types(values.is_http).map((item) => (
                <div key={item}>
                  <h4>{item.split("_").join(" ")}</h4>
                  <FieldArray name="attributes_fields">
                    {({ replace }) => (
                      <Box>
                        {renderAttributeFields(
                          values.attributes,
                          values,
                          setFieldValue,
                          handleBlur,
                          item,
                        )}
                        {renderSettingsFields(
                          values.attributes_fields,
                          item,
                          values,
                          setFieldValue,
                          handleBlur,
                          setFieldError,
                          replace,
                          errors,
                        )}
                      </Box>
                    )}
                  </FieldArray>
                </div>
              ))}
              <Grid container spacing={1} mt={1}>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant="contained"
                    sx={{ width: "100%" }}
                    type="submit"
                  >
                    Submit
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant="contained"
                    onClick={() => navigate(-1)}
                    color="error"
                    sx={{ width: "100%" }}
                    data-testid="cancelBtn"
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default AddConnectionForm;
