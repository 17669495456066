export const isRouteNotValid = (error) => {
  if (error?.response?.status === 404) {
    return true;
  }
  if (
    error?.response?.status === 422 &&
    error?.response?.data.errors[0].type === "uuid_parsing"
  ) {
    return true;
  }

  return false;
};
