import { Company } from "../../../../interfaces/Company";
import { Customer } from "../../../../interfaces/User";
import { Grid } from "@mui/material";
import UserDetails from "./UserDetails";
import UserLicenses from "./UserLicenses";
import UserPermissions from "./UserPermissions";

interface UserDetailsCardProps {
  customer: Customer;
  company: Company;
  userOrgAssignment: { uuid: string; name: string }[];
}

export default function UserDetailsCard({
  customer,
  company,
  userOrgAssignment,
}: Readonly<UserDetailsCardProps>) {
  return (
    <Grid container columnSpacing={1}>
      <Grid data-testid="company-details" item xs={12} sm={12} md={12}>
        <UserDetails
          customer={customer}
          company={company}
          userOrgAssignment={userOrgAssignment}
        />
        <UserPermissions />
        <UserLicenses />
      </Grid>
    </Grid>
  );
}
