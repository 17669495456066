import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import DeleteImage from "./DeleteImage";
import UploadIcon from "@mui/icons-material/Upload";
import VisibilityIcon from "@mui/icons-material/Visibility";

export default function ImageTable({
  image_uuid,
  polygonsNumber,
  getCameraImageRequest,
  handleUploadEditImage,
  image,
}) {
  const navigate = useNavigate();
  const params = useParams();

  return (
    <Box borderTop={"1px solid #e7e7e7"} mb={3}>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>Image</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>
                #Detection Zones
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow data-testid="camera-connection-row">
              <TableCell>
                <img
                  src={image}
                  style={{ height: "40px" }}
                  alt="detection-zone"
                />
              </TableCell>
              <TableCell>{polygonsNumber}</TableCell>
              <TableCell align="right">
                <IconButton
                  size="small"
                  onClick={() =>
                    navigate(
                      `/company/${params.company_uuid}/site/${params.site_uuid}/camera/${params.camera_uuid}/image/${image_uuid}`,
                    )
                  }
                >
                  <VisibilityIcon fontSize="small" />
                </IconButton>
                <Tooltip title="upload a replacement image" placement="top">
                  <IconButton
                    size="small"
                    data-testid="editBtn"
                    component="label"
                  >
                    <input
                      hidden
                      onChange={(e) =>
                        e.target.files &&
                        handleUploadEditImage(e.target.files[0])
                      }
                      accept="image/png, image/jpeg"
                      type="file"
                    />
                    <UploadIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                <DeleteImage
                  image_uuid={image_uuid}
                  getCameraImageRequest={getCameraImageRequest}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
